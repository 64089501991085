<template>
<div>
    <div
     ref='barchart'
     :style="echartStyle"
     class="lineechart"
    >
    </div>
 </div>   
</template>
<script >
let echarts = require('echarts')
export default {
    name: 'Bar',
  props: { 
    echartStyle: {  // 样式 - 1
      type: Object,
      default() {
        return {
          width:"99%"
        }
        
      }
    },
    titleText: {   //标题文本 - 1
      type: String,
      default: ''
    },
    
    opinion: {    //区域名称 - 1
      type: Array,
      default() {
        return []
      }
    },
    
    opinionData: {   // 区域数据 - 1
      type: Array,
      default() {
        return []
      }
    },
    
    x: {  // x 轴
      type: Array,
      default() {
        return []
      }
    },
  },
  data () {
    return {
    }
  },
 mounted(){
 this.$nextTick(()=>{
      this.drawLine();
    })
 },
  methods: {
    
      drawLine(){
        let myCharts = echarts.init(this.$refs.barchart)
        myCharts.setOption({
          title: {
            left: 'center',
                text: this.titleText, //标题文本
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
              icon:'circle',
              left: '2%',
              data: this.opinion ,// 区域名称
              
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {
                        show:false,//控制保存按钮显示隐藏
                    }
                }
            },
            xAxis: {   // x 轴
                type: 'category',
                boundaryGap: false,
                data: this.x
            },
            yAxis: {   // y 轴
                type: 'value',
            },
            series: this.opinionData  // 区域数据
        })
      window.addEventListener('resize', this.windowResizeFn)
      },
      windowResizeFn(myCharts) {
        myCharts.resize();
      }
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.windowResizeFn);
    },
}

</script>
<style lang='scss' scoped>
.lineechart{
  // margin-left: 19px;
  width: 99%;
}

</style>
