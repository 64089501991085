<template>
  <div class="objectTableMain">
    <div class="object_box">
      <div class="header-left">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          style="margin: 10px 10px 6px 10px"
        >
          <el-breadcrumb-item @click.native="goBigUrl">{{
            $store.state.channel.tabName
          }}</el-breadcrumb-item>
          <el-breadcrumb-item @click.native="goUrl">{{
            $store.state.channel.bigTitle
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>流量统计</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="header-title">流量统计</div>
        <div style="margin: 0px 10px 10px">
          <!-- 列表表头说明文字 -->
          <statistics
            :total="tableDataList.length"
            :isQueryCount="true"
            :time-interval="timeInterval"
            :show-checked-length="false"
            @refresh="refresh"
          ></statistics>
        </div>
      </div>
      <div class="header-right">
        <div class="pull-right">
          <div class="pull-right margin_10">
            <el-button size="mini" @click="delList"> 删除 </el-button>
            <el-button size="mini" @click="MailChimpShow">
              导出至MailChimp
            </el-button>
          </div>
          <div class="margin_10">
            <div class="pull-left">
              <search-wrapper
                ref="searchWrapper"
                width="280px"
                class="searchWrapper"
                :placeholder="$t('vue_label_commonobjects_view_searchthisview')"
                :exact-search-flag="exactSearchFlag"
                :exact-search-field-list="exactSearchFieldList"
                @changeKeyWord="changeKeyWord"
              >
              </search-wrapper>
            </div>
            <!-- 筛选 -->
            <div class="screen-box">
              <el-button-group>
                <!-- 筛选器 -->
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  :content="$t('label.weixin.report.filters')"
                  placement="top-start"
                  :open-delay="500"
                >
                  <el-button
                    size="mini"
                    class="btn group-btn shaixuanActive"
                    @click="screenShow()"
                  >
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-icon_screening"></use>
                    </svg>
                  </el-button>
                </el-tooltip>
              </el-button-group>
              <!-- 筛选器 -->
              <div class="filter">
                <Filter-panel
                  class="screen"
                  v-if="showScreen"
                  :viewInfos="viewInfo"
                  :view-id="viewId"
                  :objId="objId"
                  :prefix="'004'"
                  @viewIds="viewIds"
                  @filterBoard="filterBoard"
                  :style="{ height: tableHeight - 2 + 'px' }"
                >
                </Filter-panel>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 列表 -->
      <tablePanel
        :tableAttr="tableAttr"
        :tableDataList="tableDataList"
        :sequenceFlg="true"
        :operationButtonList="operationButtonList"
        @edit="edit"
        @delete="del"
        @share="share"
        @view="view"
        @onchange="onchange"
      ></tablePanel>
      <!-- 删除数据提示 -->
      <el-dialog
        :visible.sync="dialogVisible"
        :title="$t('label.emailtocloudcc.delete')"
        top="15%"
        width="25%"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <!-- 确认删除该条数据吗 -->
        <span style="font-size: 14px; color: #080707">{{
          delFlg === 1
            ? $t("label.weixin.confirm.delete")
            : $t("vue_label_commonobjects_view_notice_deletecertification", {
                selectNum: this.selectedList.length,
              })
        }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">
            <!-- 取消 -->
            {{ $t("label.ems.cancel") }}</el-button
          >
          <el-button @click="confirmDel" class="el-button--primary" size="mini">
            <!-- 删除 -->
            {{ $t("component_setup_tabs_label_delete") }}</el-button
          >
        </span>
      </el-dialog>
      <!-- 添加标签 -->
      <div class="add_label">
        <add-label ref="addlabel" :ids="ids" :objIds="objIds"> </add-label>
      </div>
      <MailChimp
        ref="MailChimp"
        :listData="ids"
        :prefix="'004'"
        :viewId="0"
        :tabName="'联系人'"
        @MailChimpSelect="MailChimpSelect"
        :selectedList="selectedList"
        @refresh="refresh"
      ></MailChimp>
      <!-- Show -->
      <el-dialog
        title="show Flow"
        :visible.sync="sharetype"
        width="80%"
        custom-class="dialog"
      >
        <div class="header ml-10">
          <h3>Posters</h3>
          <el-dropdown size="mini" class="ml-10">
            <!-- 选择渠道 -->
            <el-button size="mini">
              All<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>地铁海报</el-dropdown-item>
              <el-dropdown-item>邀约海报</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 选择时间 -->
          <el-dropdown size="mini" class="ml-10">
            <span class="el-dropdown-link">
              下拉菜单<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>Last day</el-dropdown-item>
              <el-dropdown-item>Last week</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <p class="timeTitle ml-10">123213213213123213123213 2012</p>
        <div class="mt-20">
          <Analyzeecharts
            :echartStyle="style"
            :titleText="title"
            :opinion="barName"
            :opinionData="info"
            :x="barX"
          ></Analyzeecharts>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import {
  Statistics,
  FilterPanel,
  SearchWrapper,
} from "@/components/index";
import MailChimp from '@/components/MailChimp/index.vue'
import * as CommonObjApi from "../api";
import addLabel from '@/views/marketList/marketingChannel/component/labelView/addTag.vue'
import tablePanel from '@/views/marketList/marketingChannel/component/table-panel/index.vue'
import Analyzeecharts from "@/views/marketList/components/Analyzeecharts.vue"

export default {
  name: 'maketingPostersListShow',
  components: {
    Analyzeecharts,
    MailChimp,
    SearchWrapper,
    FilterPanel,
    Statistics,
    addLabel,
    tablePanel,
  },
  data() {
    return {
      sharetype: false,
      // 流量图
      style: {
        height: '400px',
        width: "99%"
      },
      barName: ['Views', 'Submissions', 'sign in'],
      title: '',
      barX: ['3/26/2021', '3/27/2021', '3/28/2021', '3/29/2021', '3/30/2021', '3/31/2021', '4/1/2021', '4/2/2021', '4/3/2021', '4/4/2021', '4/5/2021', '4/6/2021', '4/7/2021', '4/8/2021', '4/9/2021', '4/10/2021', '4/11/2021', '4/12/2021', '4/13/2021', '4/14/2021', '4/15/2021', '4/16/2021', '4/17/2021', '4/18/2021', '4/19/2021', '4/20/2021', '4/21/2021', '4/22/2021', '4/23/2021', '4/24/2021'
      ],
      info: [
        {
          name: 'Views',
          type: 'line',
          itemStyle: { normal: { lineStyle: { color: '#E5851A' }, color: '#E5851A' }, },
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1.2, 0, 0.25, 0.5, 0.25, 0, 0.25]
        },
        {
          name: 'Submissions',
          type: 'line',
          itemStyle: { normal: { lineStyle: { color: '#51D3D9' }, color: '#51D3D9' }, },
          // eslint-disable-next-line no-sparse-arrays
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, , 0, 0.15, 0.22, 0, 0, 0.1]
        },
        {
          name: 'sign in',
          type: 'line',
          itemStyle: { normal: { lineStyle: { color: '#8AD1EB' }, color: '#8AD1EB' }, },
          // eslint-disable-next-line no-sparse-arrays
          data: [0, 0, 0, 0, 0.11, 0.23, 0, 0, 0, 0, 0.6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, , 0, 0.15, 0.22, 0, 0, 0.1]
        }
      ],
      viewInfo: { "isUsedForMobile": false, "ismodify": "false", "id": "aec201205285194prysE", "label": "全部", "isdefault": "1", "isCurrent": true },
     
      showScreen: false,
      exactSearchFlag: '', // 是否支持单字段搜索标识
      exactSearchFieldList: [], // 单字段搜索字段集合
      // 操作按钮的下拉框按钮数据
      operationButtonList: [
        { action: 'view', label: this.$i18n.t('label.emailtocloudcc.button.view') },
      ],
      objIds: '',
      url: '',
      delFlg: 0,
      ids: '',
      selectedList: [], // 表格选中数据集合
      // 列表数据
      isShowDialog: false,
      flag: false,
      tableDataList: [],
      state: "",
      selectNum: 0,
      checked: false,
      lengths: 0,
      objectApi: "", // 对象api
      objId: this.$route.query.id, // 对象id
      prefix: "", // 对象前缀
      tableHeight: "",
      tableAttr: [],
      dialogVisible: false,
      viewId: "0",
      searchKeyWord: "", // 搜索关键字
      id: "", //记录id
      timer: "", //定义一个定时器的变量
      lastRefresh: new Date(), // 最后一次更新时间
      timeInterval: "",
      labelArr: [],
      isQueryCount: true,
      newViewId: '',
      bool: null,
    };
  },
  computed: {
    contentBoxHeight() {
      let boxHeight = window.innerHeight - 220;
      return boxHeight + "px";
    },
  },

  created() {
    this.init()
    this.objIds = this.$store.state.channel.objIds
    this.objId = this.$route.params.id;
    this.prefix = this.$route.params.prefix;
  },
  mounted() {
    this.tableHeight =
      document.getElementsByClassName("home_content")[0].clientHeight - 210;
    // this.getListTable();
    // });
    // 计时器计算多久前更新
    this.timer = setInterval(() => {
      // 获取当前时间
      let currentTime = new Date();
      let interval = currentTime - this.lastRefresh;

      //计算出相差天数
      var days = Math.floor(interval / (24 * 3600 * 1000));
      //计算出小时数
      var leave1 = interval % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      hours = minutes > 30 ? hours + 1 : hours;
      if (days > 0) {
        this.timeInterval =
          hours > 0 ? `${days} ${this.$i18n.t('label.login.access.day')}${hours} ${this.$i18n.t('label_tabpage_hoursagoz')}` : `${days}${this.$i18n.t('label_tabpage_Sdfez')}`;
      } else if (hours > 0) {
        this.timeInterval = `${hours} ${this.$i18n.t('label_tabpage_hoursagoz')}`;
      } else if (minutes > 0) {
        this.timeInterval = `${minutes} ${this.$i18n.t('label_tabpage_minutesagoz')}`;
      } else {
        this.timeInterval = seconds > 43 ? this.$i18n.t('vue_label_commonobjects_view_secondsbefore', {
          seconds: seconds,
        }) : this.$i18n.t('label.notification.time1');
      }
    }, 1000);
  },
  methods: {
    MailChimpShow() {
      if (this.selectedList.length === 0) {
        this.$message.error(
          this.$i18n.t('label.integrations.mailchimp.massage1')
        )
      } else {
        this.$refs.MailChimp.open()
      }
    },
    viewIds(val) {
      this.newViewId = val
      this.getNewViewList()
    },
    // 筛选器：点击按钮及关闭的回调
    filterBoard() {
      this.showScreen = !this.showScreen
    },
    // 表格选中数据
    onchange(val) {
      this.selectedList = val
      let ids = ''
      for (var i = 0; i < val.length; i++) {
        ids += val[i].id + ','
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (ids.length > 0) {
        ids = ids.substr(0, ids.length - 1)
      }
      this.ids = ids
    },
    screenShow() {
      this.showScreen = true
    },
    tabName() {
      // 列表表头日历更改为事件
      if (this.objectApi === 'Event') {
        this.tabName = this.$i18n.t('label.event')
      }
    },
    //获取列表
    getViewListData() {

    },
    // 搜索条件改变
    changeKeyWord(val) {
      if (this.$route.params.type === 'kanban') {
        this.GetViewKanbanDisplays(val)
      } else if (this.$route.params.type === 'plan') {
        // 如果是轮班视图
        this.$refs.shiftPlan.handleSearch(val)
      } else {
        this.searchKeyWord = val
        // 特殊视图搜索条件改变时刷新表头
        if (
          this.viewId === '0' ||
          this.viewId === '1' ||
          this.viewId === '2' ||
          this.viewId === '3'
        ) {
          this.getViewListHeader()
        }
        // 清空列表选中数据
        if (this.$refs.tablePanel && this.$refs.tablePanel.checkedList) {
          this.$refs.tablePanel.checkedList = []
        }
        this.getViewListData()
      }
    },
    //全选列表数据
    MailChimpSelect(val) {
      this.bool = val
      // if (val === true) {
      //   this.$refs.tablePanel.$refs.tablePanel.toggleAllSelection()
      // } else {
      //   this.$refs.tablePanel.$refs.tablePanel.clearSelection()
      //   this.selectedList = this.$store.state.checkedNum
      //   this.$store.state.checkedNum.forEach(item => {
      //     this.$refs.tablePanel.$refs.tablePanel.toggleRowSelection(item, true)

      //   })
      // }
    },
    //分享
    share() {
    },
    //查看
    view() {
      this.sharetype = true
    },
    //标签按钮
    customBtn() {
      // this.customFlag = true;
      if (this.labelArr.length !== 0) {
        this.$refs.addlabel.labelArrs(this.labelArr)
      }
      this.$refs.addlabel.tagApi(this.objId, this.objectApi)
      this.$refs.addlabel.isShows()
    },
    //初始化 
    init() {
      this.$route.params.id
      
      this.tableAttr = [
        {
          "apiname": "posterName",
          "labelname": "来源",
          "colwidth": 200,
        },
        {
          "apiname": "posterType",
          "labelname": "查看量",
          "colwidth": 100,
        },
        {
          "apiname": "poster",
          "labelname": "提交量",
          "colwidth": 200,
        },
        {
          "apiname": "No4",
          "labelname": "现场签到数",
          "colwidth": '',
        }
      ]
      this.tableDataList = [
        {
          "posterName": "地铁海报",
          "posterType": "地铁",
          "poster": "5",
          'No4': 'no4'

        }, {
          "posterName": "地铁海报2",
          "posterType": "地铁2",
          "poster": "5",
          'No4': 'no4'
        }, {
          "posterName": "地铁海报3",
          "posterType": "地铁3",
          "poster": "5",
          'No4': 'no4'
        }
      ]
    },
    closeDialog() {
      // 刷新列表
      this.init()
    },
    addTag() {
      if (this.selectedList.length > 200 || this.lengths > 200) {
        this.$message.info(
          this.$i18n.t('vue.label.commonobjects.view.up.to.data')
        )
      } else {
        if (this.ids === '') {
          this.$message.info(this.$i18n.t('lable.opppdt.selPdt.leastone'))
        } else {
          this.$refs.addlabel.isShows()
        }
      }
    },
    goBigUrl() {
      this.$router.go(-2);
    },
    goUrl() {
      this.$router.go(-1);
    },
    // 刷新
    refresh() {
      this.init()
      this.lastRefresh = new Date();
      // 重置列表信息
      // 重置列表选中统计数据
      this.$refs.tablePanel.checkedList = [];
    },
    // 编辑
    edit(id) {
      this.$store.commit('changeItemDataId', id); // 点击编辑将每条数据的id存在state中，
      this.$store.commit('changeActionType', 'edit');
    },
    //批量删除
    delList() {
      if (this.selectedList.length > 200 || this.lengths > 200) {
        this.$message.info(
          this.$i18n.t('vue_label.commonobjects.view.up.to.data')
        )
      } else {
        if (this.ids === '') {
          this.$message.info(this.$i18n.t('lable.opppdt.selPdt.leastone'))
        } else {
          this.delFlg = 0
          this.dialogVisible = true
        }
      }
    },
    // 删除
    del(id) {
      this.delFlg = 1
      this.dialogVisible = true
      this.id = id
    },
    // 确认删除数据
    confirmDel() {
      this.dialogVisible = false;
      if (this.delFlg === 1) {
        CommonObjApi.delMarketList({ id: this.id }).then((res) => {
          if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("label_tabpage_delsuccessz"),
            })
            this.init()
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            })
          }
        })
      } else if (this.delFlg === 0) {
        CommonObjApi.delMoreMarketList({ id: this.ids }).then((res) => {
          if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("label_tabpage_delsuccessz"),
            })
            this.init()
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            })
          }
        })
      }

    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
    },
    closeAddCampaignMembersDialog() {
      this.showAddCampaignMembers = false;
    },
    // 滚动加载数据
    singleAppend() {
      this.singleSearchProducts();
    },
  },
  beforeDestroy() {
    localStorage.setItem("relevantObjectLevel", 0);
    // 在Vue实例销毁前，清除我们的定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>
<style lang="scss" scoped>
.timeTitle {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #888888;
}
.header {
  display: flex;
  justify-content: left;
}
.content {
  font-size: 14px;
  padding-left: 20px;
}
.margin_10 {
  margin: 10px;
}
.header-left {
  width: 50%;
  float: left;
  .header-title {
    font-family: PingFangSC-Medium;
    font-size: 16px;
    color: #080707;
    letter-spacing: 0;
    line-height: 30px;
    margin-left: 10px;
  }
}
.header-right {
  margin-left: 50%;
  width: 50%;
  .screen-box {
    width: 30px;
    float: right;
    position: relative;
    .filter {
      right: 0px;
      top: 40px;
      position: absolute;
      z-index: 999;
    }
  }
  ::v-deep .el-button {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #006dcc;
    letter-spacing: 0;
  }
}
.group-btn {
  // margin-top: -20px;
  padding: 0 4px;
  width: 30px;
  background-size: auto auto;
  background-repeat: no-repeat;
  background-position: 4px;
}
.btn {
  padding: 0;
  height: 29px;
  padding: 0 4px;
  border: 1px solid #dedcda;
}
.searchWrapper {
  margin-right: 10px;
  height: 41px;
}
img {
  width: 18px;
  height: 18px;
}

.shaixuanActive {
  background-size: 56%;
  background-position-x: 6px;
}
.content {
  font-size: 14px;
  padding-left: 20px;
}
.download {
  margin-left: 40px !important;
}
.wrapper {
  padding-left: 10px;
  padding-top: 10px;
  word-break: break-all;
  font-family: "Microsoft Yahei";
  float: left;
  width: 400px;
}
.add_label {
  position: absolute;
  top: 57px;
  left: 35%;
}
::v-deep .el-button-group {
  position: relative;
  .moreButton {
    margin-bottom: 0;
    li {
      height: 30px;
      line-height: 30px;

      &:hover {
        color: #006dcc;
      }
    }
  }
  ::v-deep .button {
    height: 30px;
    text-align: center;
    padding: 0 15px;
    color: #006dcc;
    border: 1px solid #dedcda;
    &:hover {
      background: #fff;
      border: 1px solid #dedcda;
    }
  }
}
::v-deep .el-table--border {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

::v-deep .el-table::before {
  height: 0;
}

::v-deep .el-table__fixed::before {
  height: 0;
}

.objectTableMain {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 10px 10px;
  .object_box {
    border-radius: 3px;
    border: 1px solid #dedcda;
    height: 100%;
    background: #f5f5f5;
    ::v-deep .el-breadcrumb__inner {
      cursor: pointer;
    }
  }
}

.topContent {
  width: 100%;
  // background: #fff;
  ::v-deep .el-breadcrumb__inner {
    color: #006dcc;
    font-size: 12px;
  }
}

.left-content {
  width: 50%;
  padding: 10px 10px 0;
}

.right-content {
  width: 50%;
  padding: 13px 10px 0 0;

  .searchWrapper {
    margin-right: 12px;
    height: 41px;
  }
}

.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}
.spancolor {
  color: #fa6400;
}
.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;
}
.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;

  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}

::v-deep .el-input {
  height: 30px;
  width: 100%;
  text-align: center;
}
::v-deep .el-input__inner {
  height: 30px;
  width: 100%;
}
::v-deep .el-button--mini {
  padding: 7px 7px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input__icon {
  height: auto;
}
.al {
  margin: 0 24px;
  width: auto;
  overflow: auto;
  max-height: 250px;
  border: 1px solid rgba(190, 225, 255, 1);
}
::v-deep .el-input__icon {
  margin-top: -4px;
}
ul {
  margin-bottom: 0;
}
.list_xiala_s {
  width: 32px;
  height: 32px;
  text-align: center;
  cursor: pointer;
  padding: 0;
}
.list_xiala_s img {
  width: 16px;
}
.list_xiala_s:hover {
  background: #fff;
  border: 1px solid #dedcda;
}
.moreButton {
  margin: 0 -12px;
  min-height: 26px;
}
.moreButton li {
  min-height: 26px;
  font-size: 12px;
  padding: 0;
  text-align: center;
  line-height: 26px;
}

.moreButton li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
::v-deep .dropbox {
  width: 38px;
}
</style>
<style lang="scss" scoped>
.content-main {
  position: relative;
  clear: both;
}

.showEditTableField-box {
  position: absolute;
  top: 11px;
  left: 18px;
  z-index: 100;
}

.action-btn {
  color: #006dcc !important;
}

// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  color: #ccc;
  float: left;
  display: contents;
}

.stars span {
  font-size: 20px;
  margin-left: 3px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

.table-head-tool {
  .el-input {
    position: absolute;
    top: -3px;
    left: -10px;
    margin-left: 10px;
    // width: calc(100% - 40px);
  }

  ::v-deep .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    height: 25px;
    padding-left: 0;
  }

  .dragBox {
    width: 49%;
  }

  .item-box-left-container {
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 250px;
    max-height: 250px;

    .searchBox {
      position: relative;
      padding: 0 10px;
      top: 5px;
      height: 25px;
      margin-bottom: 0;

      i {
        position: absolute;
        z-index: 100;
      }

      ::v-deep .el-input__inner {
        padding-left: 32px;
      }
    }
  }

  .itemBox {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px 0;
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
  }

  .itemBox-left {
    border: none;
    min-height: 200px;
    max-height: 222px;
    margin-top: 2px;
  }

  .dragItem {
    width: 100%;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    padding: 0 10px;
    cursor: move;

    &:hover {
      background: #fafaf9;
    }
  }

  .btns {
    width: 95%;
    margin: 10px 15px 10px 0;
    text-align: right;
    position: absolute;
    bottom: 0;
  }
}

// 进度条样式修改
::v-deep .el-progress-bar {
  padding-right: 14px;
}

.fileItem {
  color: #489ad9;
  cursor: pointer;
  margin-right: 5px;
}

::v-deep .el-dialog__header {
  text-align: left;
}

::v-deep .el-table-column--selection .cell {
  text-align: center;
}

::v-deep .el-table__fixed-body-wrapper {
  // top: 48px !important;
}

::v-deep .el-dialog__footer {
  clear: both;
}

// 表格纵向滚动条
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: auto;
}

.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;

  .popover-span {
    position: absolute;
    right: 0;
  }
}

// 提示语
.noDataTip {
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  background: #fff;
}

// 表头样式
.el-table--border,
.el-table--group {
  border: 1px solid #dedcda;
}

::v-deep .el-table th.is-leaf {
  color: #666666;
  border-bottom: 1px solid #dedcda;
  font-size: 12px;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}

::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}

// 标签样式
.tag {
  font-size: 12px;
  padding: 5px;
  height: 25px;
  line-height: 15px;
  margin-right: 5px;
}

::v-deep .el-tag--dark {
  background-color: #62a5f3;
  border-color: #62a5f3;
}

::v-deep .el-tag--dark.el-tag--info {
  background-color: #dedcda;
  border-color: #dedcda;
}

::v-deep .el-tag--dark.el-tag--danger {
  background-color: #f6817b;
  border-color: #f6817b;
}

::v-deep .el-tag--dark.el-tag--success {
  background-color: #7cedcc;
  border-color: #7cedcc;
}

::v-deep .el-tag--dark.el-tag--warning {
  background-color: #fec366;
  border-color: #fec366;
}

// 进度条样式
.el-progress {
  display: inline;
}

::v-deep .el-table td .cell {
  padding-right: 20px;
}
::v-deep .el-table th .cell {
  font-size: 12px;
}

::v-deep .el-table td:first-child .cell {
  padding-right: 14px;
}

// 表头颜色自定义、单元格超出...显示
::v-deep .el-table__header {
  img {
    margin-top: -4px;
  }

  tr th {
    background: #fafaf9;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th .cell {
    padding-right: 14px;

    .el-checkbox {
      margin-left: 0;
    }
  }
}

.admin_s {
  margin-top: -4px;
  cursor: pointer;
  width: 14px;
}

::v-deep .el-table .cell {
  min-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  // padding-right: 20px;
}

// #table-panel .content-main .el-table {
//   tr th {
//     background: #006dcc;
//   }
// }

::v-deep .el-table td {
  font-size: 12px;
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border td {
  border-right: none;
}

.allow-click {
  color: #006dcc;
  text-decoration: solid;
}
::v-deep .is-checked {
  .el-checkbox__input {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
::v-deep .el-checkbox {
  .is-indeterminate {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
</style>
<style scoped>
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
</style>
